.login-as-list-wrapper {
  border: 1px #d9d9d9 solid;
  border-radius: 6px;
  width: 80%;
}

.login-as-list-header {
  color: #909090;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px #d9d9d9 solid;
}

.login-as-list {
  /* // */
}

.login-as-list-item {
  color: #2d2d2d;
  padding: 12px 24px;
  border-bottom: 1px #d9d9d9 solid;
  font-size: 1.2rem;
  text-align: left;
}
